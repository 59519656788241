<template>
  <div class="box" v-loading.fullscreen.lock="loading">
    <img
      class="m_tb_20 pointer"
      :src="banner"
      alt=""
      width="100%"
      @click="toUploadWorks_click"
    />
    <el-breadcrumb class="m_b_10" separator-class="el-icon-arrow-right">
      <el-breadcrumb-item
        :to="{
          name: 'regionResource',
          params: { classify: '1555104854509219841', name: '劳动教育' },
        }"
      >
        劳动教育
      </el-breadcrumb-item>
      <el-breadcrumb-item>教师专享</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="bg_fff br_5 p_in_20 m_b_20 fs_16">
      <input
        type="radio"
        name="type"
        id="resource"
        value="1603289215070707714"
        v-model="selected"
      />
      <label
        class="m_r_20 pointer"
        for="resource"
        @click="select_click('1603289215070707714')"
      >
        微课资源
      </label>
      <input
        type="radio"
        name="type"
        id="video"
        value="1603289255893868545"
        v-model="selected"
      />
      <label
        class="pointer"
        for="video"
        @click="select_click('1603289255893868545')"
      >
        课堂实录
      </label>
      <input
          type="radio"
          name="type"
          id="skill"
          value="1603289255893868"
          v-model="selected"
      />
      <label
          class="pointer"
          for="skill"
          @click="select_click('1603289255893868')"
      >
        教师技能大赛
      </label>
    </div>
    <div class="flex_row_wrap" v-if="courseList.length > 0">
      <template v-if="selected === '1603289215070707714'">
        <div
          class="course bg_fff br_10 pointer"
          v-for="item in courseList"
          :key="item.id"
          @click="toDetail_click(item)"
        >
          <img class="cover-img" :src="item.coverUrl" alt="" />
          <div class="fs_18 c_333">{{ item.name }}</div>
          <div class="fs_12 c_666">学段：{{ item.info.period }}</div>
          <div class="tag">
            <i class="el-icon-video-play"></i>
            视频
          </div>
        </div>
      </template>
      <template v-else>
        <div
          class="course bg_fff br_10 pointer"
          v-for="item in courseList"
          :key="item.id"
          @click="toDetail_click(item)"
        >
          <img class="cover-img" :src="item.coverUrl" alt="" />
          <div class="fs_18 c_333">{{ item.name }}</div>
          <div class="course-info fs_12 c_666">
            <span>学段：{{ item.info.period }}</span>
            <span>年级：{{ item.info.grade }}</span>
            <span>学科：{{ item.info.subject }}</span>
            <span>教师：{{ item.info.teacher }}</span>
          </div>
          <div class="fs_12 c_666" v-if="selected == 1603289255893868">
                <span>奖项：{{ item.awards }}</span>
<!--            <span>奖项：第一届武侯区劳动教育教师技能大赛—劳动教育视频课一等奖</span>-->
          </div>
          <div class="tag">
            <i class="el-icon-video-play"></i>
            视频
          </div>
        </div>
      </template>
    </div>
    <el-empty v-else></el-empty>
    <el-pagination
        v-if="total > params.size"
      style="text-align: center; margin-top: 10px"
      hide-on-single-page
      :current-page.sync="params.current"
      @current-change="current_change"
      layout="prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import course from "assets/image/Group 651.png";
import banner from "assets/image/Group 640.png";
import { getResourceList } from "@/api/resourceCenter";
import { htmlUnEscape } from "utils/utils";
import { sessionGet } from "../../../../../../utils/local";
import { getPhoneEncrypt } from "../../../../../../api/resourceCenter";

export default {
  name: "PracticeAssistant",
  data() {
    return {
      course,
      banner,
      selected: "1603289215070707714",
      loading: false,
      params: {
        contentsId: "",
        current: 1,
        size: 12,
      },
      total: 0,
      courseList: [],
    };
  },
  async mounted() {
    let params = this.$route.params;
    let type = this.$route.query.type;
    if (params.current) {
      await this.getResource();
      this.params.current = params.current ? Number(params.current) : 1;
    }
    if (type)
      this.selected =
        type === "resource" ? "1603289215070707714" : (type === "video" ? "1603289255893868545" : "1603289255893868");
    this.getResource();
  },
  methods: {
    async getResource() {
      this.loading = true;
      this.params.contentsId = this.selected;
      await getResourceList(this.params).then((res) => {
        this.courseList = res.data.records.map((item) => {
          return {
            name: item.name,
            coverUrl: item.coverUrl,
            introduce: item.introduce.split("&&")[0],
            info: item.introduce.split("&&")[1]
              ? JSON.parse(htmlUnEscape(item.introduce.split("&&")[1]))
              : {},
            id: item.id,
            resourcesUrl: item.resourcesUrl,
            browseNumber: item.browseNumber,
            type: item.type,
            awards: item.awards,
          };
        });
        this.total = res.data.total;
        console.log('total::::'+ JSON.stringify(this.total));
      });
      this.loading = false;
    },
    current_change(current) {
      this.params.current = current;
      this.getResource();
    },
    select_click(id) {
      this.selected = id;
      this.params.current = 1;
      this.getResource();
    },
    toDetail_click(data) {
      let childType = "resource";
      if (this.selected == "1603289215070707714") {
        childType = "resource";
      } else if (this.selected == "1603289255893868545") {
        childType = "screenRecording";
      }else if(this.selected == "1603289255893868"){
        childType = "techerJN";
      }
      // this.$router.push({
      //   path: "/resource/practiceAssistantDetail",
      //   query: {
      //     id: data.id,
      //     type: "2",
      //     current: this.params.current,
      //     childType,
      //   },
      // });
      this.$router.push({
        path: "/resource/details",
        query: {
          id: data.id,
          scene: 'regionResource'
        },
      });
    },
    toUploadWorks_click() {
      let userInfo = sessionGet("userInfo");
      if (!userInfo) return this.$message.warning("请登录");
      getPhoneEncrypt({ id: userInfo.user_id }).then((res) => {
        if (res.code !== 200) return this.$message.error(res.msg);
        window.open(
          `https://cdwh.wuhousmartedu.com/portalJump/toActivityGuide/${res.data}`
        );
      });
    },
  },
};
</script>

<style scoped>
.box {
  width: 1200px;
  margin: 0 auto;
}

input {
  display: none;
}

label {
  padding: 8px 20px;
  border-radius: 30px;
  background-color: rgba(18, 194, 148, 0.05);
}

input[type="radio"]:checked + label {
  color: #fff;
  background: linear-gradient(180deg, #64f4cf, #12c294 100%);
}

.course {
  width: 290px;
  margin-right: 13px;
  margin-bottom: 10px;
  position: relative;
  overflow: hidden;
}

.course:nth-child(4n) {
  margin-right: 0;
}

.course > div {
  padding: 5px 10px;
}

.tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.2);
  color: #fff;
  font-size: 14px;
  border-radius: 0 10px 0 10px;
}

.course-info {
  display: grid;
  grid-template-columns: repeat(2, 50%);
}

.cover-img {
  width: 100%;
}
</style>
